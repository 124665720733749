.navbar_text {
    color: #FFFFFF;
    text-decoration: none;
}
.topFixedBanner {
    background-image: url("../images/background_image_1.jpg");
    min-height: 250px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
a.navbar_text:hover {
    color: #DDDDDD;
}
.accordion-button {
    background-color: #f2f2f2 !important;
    color: #1f1f2e;
}
.accordion-button:not(.collapsed) {
    color: #1f1f2e;
}
.orange_button {
    background: linear-gradient(to right, #ff555b, #ff531a) !important;
    cursor: pointer;
    border-radius: 4px;
    display: inline-block;
    outline: none;
    border: none;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    padding: 15px 25px;
    font-size: 0.875em;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: bold;
}
.url_link {
    color:#ff531a;
    font-weight:bold;
    text-decoration:none;
}
.date_custom {
    display: block;
    width: 300px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.progress {
    height: 2rem !important;
    font-size: 1.2rem !important;
}
.progress-bar {
    background-color: #ff531a !important;
    transition: width 1.5s ease !important;
}
.footer_section {
    background-color: #282c34;
}
.footer_inner_section {
    color:#FFFFFF;
    padding: 50px 5px 20px 5px;
    text-align: justify;
}
@media screen and (max-width: 30em) {
    .orange_button {
        padding: 10px 4px;
        font-size: 0.6em;
    }
}

label.required:after {
    content:" *";
    color: red;
    font-size: 20px;
    font-weight: bold;
}

.newClass {
    border-color: RED;
    background: #0c4128;
}
/*
.react-datepicker-popper {
    display: none;
}
*/

/*
.swal-button {
    background: linear-gradient(to right, #ff555b, #ff531a) !important;
    cursor: pointer;
    border-radius: 4px;
    display: inline-block;
    outline: none;
    border: none;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    padding: 15px 25px;
    font-size: 0.875em;
    color: #FFFFFF !important;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: bold;
}
*/
.swal-button {
    background: linear-gradient(to right, #ff555b, #ff531a) !important;
    color: #FFFFFF !important;
}

.swal-button:hover {
    color: #212529 !important;
}
.swal-button--confirm:hover {
    color: #212529 !important;
}
.btn:hover {
    color: #212529 !important;
}










.image_content {
    position: relative;
    height: 650px;
}

.image_content img {
    height: 650px;
    background-size: cover;
}

.text-block {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: rgba(0,0,0,0.6);
    color: white;

    width: auto;
    display: inline-block;
    padding: 25px;
    border-radius: 10px;
}

.text-block-button {
    color: #D64C4C;
}

.text-block-button > a {
    font-size: 14px;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    background: #D64C4C;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
}

.slick-arrow, .slick-prev {
    z-index: 1;
    top: 47% !important;
}


.slick-next:before, .slick-prev:before {
    font-size: 40px !important;
}

.slick-next {
    right: 32px !important;
}

.slick-prev {
    left: 12px !important;
}

.service-v1 {
    margin-top: 50px;
    background: #fff;
    padding-top: 50px;
}

.service-v1 .heading {
    margin-bottom: 50px;
}

.service-v1 .heading h2 {
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    color: #D64C4C;
}

.service-v1 .heading p {
    font-size: 14px;
    text-align: center;
    color: #333;
    font-style: italic;
    margin: 0 0 10px 0;
}

.service-v1 .item {
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 6px;
    border: 1px solid #ddd;
}

.service-v1 .photo {
    width: 100%;
    height: 180px;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
}

.service-v1 .text, .attorney-v1 .text {
    width: 100%;
    height: auto;
    padding-top: 10px;
    background: #efeded;
}

.service-v1 .text .inner, .attorney-v1 .text .inner {
    padding: 10px;
}

.service-v1 .text h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
}

.service-v1 .text h3 a, .attorney-v1 .text h3 a {
    color: #D64C4C!important;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none !important;
}

.service-v1 .text p, .attorney-v1 .text p {
    font-size: 14px;
    line-height: 1.4;
    color: #656464;
}

.service-v1 .text p.button a, .attorney-v1 .text p.button a {
    color: #4a4a4a;
    border-radius: 4px;
    font-weight: bold;
    font-size: 14px;
    text-decoration: none!important;
    cursor: pointer;
}

.service-v1 .text p.button, .attorney-v1 .text p.button {
    margin-top: 20px;
}



.attorney-v1 {
      margin-top: 50px;
      background: #fff;
      padding-top: 50px;
}

.attorney-v1 .heading {
    margin-bottom: 50px;
}

.attorney-v1 .heading h2 {
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    color: #D64C4C;
}

.attorney-v1 .heading p {
    font-size: 14px;
    text-align: center;
    color: #333;
    font-style: italic;
}

.attorney-v1 .item {
    padding: 0px 5px 0px 5px;
}

.attorney-v1 .item .thumb {
    position: relative;
    overflow: hidden;
    border: 1px solid #ddd;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.attorney-v1 .item .thumb .photo {
    width: 100%;
    height: 275px;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: top center;
}

.attorney-v1 .item .thumb .overlay {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: scale(0.2);
    -ms-transform: scale(0.2);
    -o-transform: scale(0.2);
    transform: scale(0.2);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    background: rgba(32, 60, 67, 0.6);
}

.attorney-v1 .item .social-icons {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.attorney-v1 .social-icons ul {
    text-align: center;
    margin-top: calc(100% - 40px);
    margin-bottom: 10px;
}

.attorney-v1 .social-icons ul li {
    list-style-type: none;
    margin-bottom: 10px;
    display: inline-block;
    margin-right: 5px;
}

.attorney-v1 .social-icons ul li a {
    width: 30px;
    line-height: 30px;
    background: #D64C4C;
    color: #fff;
    display: block;
    text-align: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    font-size: 16px!important;
}

.attorney-v1 .text {
    background: #efeded;
    padding: 15px;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.attorney-v1 .text h3 {
    text-align: center;
    color: #D64C4C;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 5px;
}

.attorney-v1 .text h3 a {
    color: #D64C4C;
}

.attorney-v1 .text p {
    text-align: center;
    font-size: 14px;
    margin-bottom: 0;
    color: #656464;
}

.testimonial-v1 {
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    display: block;
    margin-top: 80px;
}

.testimonial-v1 .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #D64C4C;
    opacity: 0.9;
    filter: alpha(opacity=90);
}

.testimonial-v1 .heading {
    margin-bottom: 50px;
    position: relative;
}

.testimonial-v1 .heading h2 {
    font-size: 30px;
    text-align: center;
    color: #FFFFFF !important;
    text-transform: uppercase;
    font-weight: 600;
}

.testimonial-v1 .heading p {
    font-size: 16px;
    text-align: center;
    color: #fff;
}

.testimonial-v1 .testimonial-carousel {
    text-align: center;
    position: relative;
    width: 70%;
    margin: 0 auto;
    display: block;
}

.testimonial-v1 .content .comment {
    color: #fff;
    padding: 15px 50px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    position: relative;
    font-style: italic;
}

.testimonial-v1 .content .comment p {
    margin: 0 auto;
    color: #fff;
    font-size: 14px;
}

.testimonial-v1 .author {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.testimonial-v1 .photo {
    display: inline-block;
    margin-right: -4px;
    vertical-align: top;
}

.testimonial-v1 .photo img {
    width: 80px;
    height: 80px;
    float: right;
    margin-right: 25px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
}

.testimonial-v1 .text {
    display: inline-block;
    vertical-align: top;
}

.testimonial-v1 .text h3 {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    text-align: left;
    margin-top: 10px;
}

.testimonial-v1 .text h4 {
    font-size: 13px;
    color: #fff;
    text-align: left;
}

.news-v1 {
    background: #fff;
    padding-top: 50px;
    padding-bottom: 50px;
}

.news-v1 .heading {
    margin-bottom: 50px;
}

.news-v1 .heading h2 {
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    color: #D64C4C;
}

.news-v1 .heading p {
    font-size: 14px;
    text-align: center;
    color: #333;
    font-style: italic;
}

.news-v1 .news-carousel {
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
}

.news-v1 .news-carousel {
    position: relative;
}

.news-v1 .item .thumb .photo {
    width: 100%;
    height: 180px;
    background-position: top center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
}

.news-v1 .text {
    background: #fff;
    padding: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.news-v1 .text h3 {
    text-align: left;
    color: #333;
    font-weight: bold;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 5px;
    line-height: 1.5;
}

.news-v1 .text h3 a {
    color: #333;
    text-decoration: none;
    cursor: pointer;
}

.news-v1 .text h3 a:hover {
    color: #D64C4C;
}

.news-v1 .text p {
    text-align: left;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.4;
}

.partner-v1 {
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    background: #f2f3f3;
}

.partner-v1 .heading {
    margin-bottom: 50px;
}

.partner-v1 .heading h2 {
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    color: #D64C4C;
}

.partner-v1 .heading p {
    font-size: 14px;
    text-align: center;
    color: #333;
    font-style: italic;
}

.partner-v1 .partner-carousel {
    position: relative;
}

.partner-v1 .item .inner img {
    display: block;
    width: 100%;
    -webkit-transform-style: preserve-3d;
}

.footer-social {
    width: 100%;
    height: auto;
    padding: 20px 0;
    background: #D64C4C;
}

.footer-social .item ul {
    margin-bottom: 0;
    text-align: center;
}

.footer-social .item ul li {
    list-style-type: none;
    display: inline-block;
    margin-right: 5px;
    background: #D64C4C;
}

.footer-social .item ul li a {
    color: #fff;
    text-align: center;
    display: block;
    width: 42px;
    height: 42px;
    line-height: 42px;
    border: 2px solid #D64C4C;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    font-size: 20px;
}

.footer-main {
    width: 100%;
    height: auto;
    padding: 50px 0;
    background: #333434;
}

.footer-col h3 {
    font-family: 'Montserrat', sans-serif;
    color: #ffffff;
    font-size: 16px;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 25px;
}

.footer-col h3:after {
    content: "";
    position: absolute;
    width: 50px;
    background: #D64C4C;
    left: 0;
    bottom: 0;
    height: 2px;
}

.footer-col p {
    color: #b9b9b9;
    font-size: 13px;
    line-height: 22px;
    margin: 0 0 10px;
}

.footer-col .news-item {
    color: #999;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px dashed #676666;
}

.footer-col .news-item .news-title {
    font-size: 13px;
    line-height: 22px;
}

.footer-col .news-item .news-title a {
    color: #b9b9b9;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    text-decoration: none!important;
    cursor: pointer;
}

.footer-col .news-item .news-title a:hover {
    color: #fff;
    outline: none!important;
}

.footer-col .contact-item {
    color: #b9b9b9;
    font-size: 13px;
    overflow: hidden;
    margin-bottom: 10px;
}

.footer-col .contact-item .icon {
    float: left;
    font-size: 16px;
    margin-right: 10px;
}

.footer-col .contact-item .text {
    float: left;
    line-height: 22px;
}



















































































